import React from 'react';
import {WarningOutlined} from '@ant-design/icons';
import * as Antd from 'antd';

import * as hooks from '../../../../hooks';
import Button from '../../../atoms/nButton';
import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';

import {usePostControlmeasuresSave} from './hooks';
import * as Styled from './styled';

const { useBoolean } = hooks;

export default function EditClassifierMeasures(props) {
  const {
    isRender,
    project,
    formik,
    onShowEditClassifierMeasures,
    onPostProjectRiskassessment
  } = props;


  const [confirmClassifierMeasures, onConfirmClassifierMeasures] = useBoolean(false);

  const {onPostControlmeasuresSave} = usePostControlmeasuresSave({
    formik,
    project,
    onShowEditClassifierMeasures,
    onConfirmClassifierMeasures,
    onPostProjectRiskassessment
  });
  const {value} = formik?.getFieldProps('rowEditControlmeasures');

  const onChange = (value) => {
    formik?.setFieldValue('rowEditControlmeasures.rate', value);
  };

  const onControlmeasuresAdd = () => {
    onPostControlmeasuresSave({
      id: value?.id,
      rate: value?.rate
    });
  }

  return (
    <React.Fragment>
      <Portal id={'editClassifierMeasures'}>
        <Dialog
          isRender={isRender}
          isClose={true}
          centered={true}
          onClose={onShowEditClassifierMeasures.off}
          title={'МЕРА УПРАВЛЕНИЯ'}
          settings={{
            title: {
              size: 14,
              fWeight: 600,
              lHeight: 17,
              align: 'left',
              margin: '-2px -20px 40px'
            },
            close: {
              height: 28,
              width: 28,
              right: '8px',
              top: '8px',
            }
          }}
        >
          <div>
            <Styled.FlexFields
              jContent={'space-between'}
              wrap={'nowrap'}
              grow={[1, 0]}
              paddingItem={['0 10px 0 0', '0 0 0 10px']}
            >
              <React.Fragment>
                <Styled.Label>
                  Наименование
                </Styled.Label>
                <Styled.Name>
                  {value?.name ?? ''}
                </Styled.Name>
              </React.Fragment>
              <React.Fragment>
                <Styled.Label>
                  Коэффициент
                </Styled.Label>
                <Antd.InputNumber
                  value={value?.rate ?? '0.01'}
                  defaultValue="0.01"
                  min="0.01"
                  max="0.99"
                  step="0.01"
                  onChange={onChange}
                  stringMode={true}
                />
              </React.Fragment>
            </Styled.FlexFields>
            <Styled.Buttons jContent={'flex-end'}>
              <Button
                variant={'secondary'}
                onClick={onConfirmClassifierMeasures.on}
              >
                Сохранить
              </Button>
            </Styled.Buttons>
          </div>
        </Dialog>
    </Portal>
    <Portal id={'confirmClassifierMeasures'}>
        <Dialog
          isRender={confirmClassifierMeasures}
          isClose={true}
          centered={true}
          onClose={onConfirmClassifierMeasures.off}
          settings={{
            close: {
              height: 28,
              width: 28,
              right: '8px',
              top: '8px',
            }
          }}
        >
          <Styled.FlexConfirm
            jContent={'space-between'}
            wrap={'nowrap'}
            paddingItem={['0 10px 0 0', '0 0 0 10px']}
          >
            <WarningOutlined style={{ fontSize: 40, color: '#FFB82E' }} />
            <React.Fragment>
              {
                'При изменении весового коэффициента система пересчитает\n' +
                'уровни риска на рабочих местах, для которых реализована\n' +
                'данная мера управления.'
              }
            </React.Fragment>
          </Styled.FlexConfirm>
          <Styled.Buttons jContent={'center'}>
            <Styled.ButtonConfirm
              variant={'secondary'}
              onClick={onControlmeasuresAdd}
            >
              Подтвердить
            </Styled.ButtonConfirm>
          </Styled.Buttons>
        </Dialog>
    </Portal>
  </React.Fragment>
)}

