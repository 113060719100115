import { useState } from 'react';

//import { Provider } from 'react-redux';
//import { createPortal } from 'react-dom';
import ModalContent from './ModalContent';

export default function PortalModal(data) {
  const [showModal, setShowModal] = useState(false);

  //console.log(data.data.name)
//console.log(table.table)
  return (
      <>
          <span  style={{"cursor": 'pointer', color: "blue" }} onClick={() => setShowModal(true)}>
              Открыть
          </span>

              <ModalContent

              showModal = {showModal}
              table = {data.data.table}
              name = {data.data.name}
              //onOpen={() => setShowModal(false)}
              onClose={() => setShowModal(false)}

              />

      </>
  );

}
