export const navMap = [
  {
    id: 2,
    label: 'Оценка',
    path: '/grade/projects',
    canView: ['admin-role', 'customer-role', 'analytic-role', 'start-role'],
  },
  {
    id: 3,
    label: 'Аналитика',
    path: '/analytics/dashboard',
    canView: ['admin-role', 'customer-role'],
  },
  {
    id: 4,
    label: 'Информационные материалы',
    path: '/information',
    canView: ['admin-role', 'customer-role', 'analytic-role', 'pm-role', 'start-role'],
  },
  {
    id: 5,
    label: 'Администрирование',
    path: '/administration',
    canView: ['admin-role', 'analytic-role'],
  },
  {
    id: 6,
    label: 'Классификаторы',
    path: '/analitic/classifiers',
    canView: ['admin-role'], //'customer-role'],
  },
   {
     id: 7,
     label: 'База знаний',
     path: '/knowledge-base/hac',
     canView: ['admin-role'],
   },
  // {
  //   id: 8,
  //   label: "Организация ОПР",
  //   path: "/organizations",
  //   canView: ["admin-role", "analytic-role"],
  // },

  // {
  //   id: 9,
  //   label: "Управление ПР",
  //   path: "/management/results",
  //   canView: ["admin-role", "pm-role"],
  // },


];
