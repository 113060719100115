import styled, { css } from 'styled-components';

import Flex from '../../../../../atoms/flex';
import Input from '../../../../../atoms/input';

export const Buttons = styled(Flex)`
  margin-top: 26px;
  padding-bottom: 0px;
`;

export const InputText = styled(Input)`
  background: #fff;
  font-size: 16px;
  line-height: 22px;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
  }
`;
export const FieldLabel = styled(Text)`
  font-family: 'GolosTextWebVF', serif;
  padding: '0 0 12px';
  display: inline-block;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}}
`;
export const InputTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #18171C;
  border-radius: 8px;
  padding: ${(props) => props.padding ?? '10px 20px'};

  &:hover {
    border-color: #AAA;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: #014880;
  }`;

  export const InputNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #18171C;
  border-radius: 8px;
  padding: 2px 5px 2px 35px};

  &:hover {
    border-color: #AAA;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: #014880;
  }`;
