import React from "react";
import { Col, Radio, Row } from 'antd';
import {Checkbox} from "antd";
import Cookies from 'universal-cookie';

import Dialog from '../../../blocks/dialog';
import Portal from '../../../blocks/portal';
import message from '../../../message/message.png'

import * as Styled from './styled';

function Formats(props) {
  const {
    formats,
    formik,
    name
  } = props;

  return formats?.map((_, index) => {
    const {value} = formik?.getFieldProps(`${name}.Formats`) ?? {};
    const {value: checkFormat} = formik?.getFieldProps(`${name}.isNotDisabled.${index}`) ?? {};

    const onChange = (format) => () => {
      formik?.setFieldValue(`${name}.Formats.0`, format);
      formik?.setFieldTouched(`${name}.Formats.0`, true);
    };

    if ((checkFormat === "null") || !checkFormat) {
      return (
        <Styled.FlexListOfReportsCircle>
          <Radio
            disabled={true}
          />
        </Styled.FlexListOfReportsCircle>
      )
    }

    return (
      <Styled.FlexListOfReportsCircle>
        <Radio
          onChange={onChange(checkFormat)}
          checked={!!value[0]?.length ? value[0] === checkFormat : false}
        />
      </Styled.FlexListOfReportsCircle>
    )
  })
}

export default function ListOfReports(props) {

  const {
    formik,
    show,
    reportsList,
    onClose,
    onGenerateReport
  } = props;
  //formik.values.check = false;
  const Message = () =>  {
    const cook = new Cookies()
    if (cook.get('Role') === 'start-role')  {
      return (<div style={{"margin-top": "20px"}}>
            <Row justify="space-around" align="middle" width={100}>
              <Col span={4}>
              <img src={message} width={"50px"}></img>
              </Col>
              <Col span={20}>
                <p style={{"font-size": "11px",  "margin": "auto",  align: "center"}}>Форматы Excel и Word доступны для пользователей с ролью “Профессионал”.
                За дополнительной информацией обратитесь к администаторам АИС “РискЛаб”.</p>
              </Col>
            </Row>
          </div>);
    }else{
      return null;
    }
  }

  const isDisabled = !!formik.touched?.reportsList?.length;

  return (
    <Portal id={'listOfReports'}>
      <Dialog
        isRender={show}
        isClose={true}
        centered={true}
        onClose={onClose}
        title={'ПЕРЕЧЕНЬ ОТЧЕТОВ'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '1px -20px 20px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
        <Styled.FlexListOfReportsTop
         paddingItem={['0 5px 15px', '0 5px 15px', '0 0 15px 5px']}
        >
          <Styled.ListOfReportsTypeFile
            background={'#01723A'}
          >
            EXCEL
          </Styled.ListOfReportsTypeFile>
          <Styled.ListOfReportsTypeFile
            background={'#075794'}
          >
            WORD
          </Styled.ListOfReportsTypeFile>
          <Styled.ListOfReportsTypeFile
            background={'#EF4036'}
          >
            PDF
          </Styled.ListOfReportsTypeFile>
        </Styled.FlexListOfReportsTop>
        <Styled.FlexListOfReportsBody
          wrap={'nowrap'}
        >
          {reportsList.map((el, index) => {
            return (
              <Styled.FlexListOfReportsBodyRow
                key={el?.id}
                widthItem={['calc(100% - 60px * 3) ', '180px']}
                grow={[1]}
              >
                <Styled.FlexListOfReportslabel>
                  {el?.Name}
                </Styled.FlexListOfReportslabel>
                <Styled.FlexListOfReportsRadio>
                  <Formats
                    formik={formik}
                    formats={el?.isNotDisabled}
                    name={`reportsList.${index}`}
                  />
                </Styled.FlexListOfReportsRadio>
              </Styled.FlexListOfReportsBodyRow>
            )})}
          </Styled.FlexListOfReportsBody>
          <Message />
          {<Checkbox style={{"padding-top": "10px", "padding-buttom": "-10px"}} onChange={(e)=> {
                  formik.values.check = e.target.checked;
                  console.log(e.target.checked);
                  }}>Ознакомить работников с результатами ОПР</Checkbox>}

            <Styled.Buttons jContent={'flex-end'}>
              <Styled.ListOfReportsButton
                variant={'primary'}
                onClick={onGenerateReport}
                disabled={!isDisabled}
              >
                Сформировать
              </Styled.ListOfReportsButton>
            </Styled.Buttons>


      </Dialog>
    </Portal>
  )
}
